import styles from "./styles.module.scss";

interface TitleProps {
  children: string;
  margin?: { marginMobile: number; marginDesktop: number };
  color?: string;
  maxWidth?: string;
}

export default function Title({ children, color, maxWidth }: TitleProps) {
  return (
    <h2
      className={styles.container}
      style={{
        color: color ? color : "#0000ff",
        maxWidth: maxWidth,
      }}
    >
      {children}
    </h2>
  );
}
