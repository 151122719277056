"use client";
import { useEffect, useState } from "react";
import { useRouter } from "next/router";
import Image from "next/image";
import Link from "next/link";
import styles from "./styles.module.scss";
import { useParams } from "../../hooks/Params";

interface Props {
   onClick?: () => void;
}

export default function Header({ onClick }: Props) {
   const [isOpen, setIsOpen] = useState<boolean>(false);
   const { pathname } = useRouter();
   const { params } = useParams();

   function toogleMenu() {
      setIsOpen(!isOpen);
   }

   return (
      <header className={styles.wrapper}>
         <nav>
            <div className={styles.logoWrapper}>
               <Link href={`/${params}`}>
                  <a>
                     <Image
                        src="/images/logo.png"
                        alt="Kenzie Academy"
                        width="222"
                        height="55"
                     />
                  </a>
               </Link>
               <button className={styles.mobileBtn} onClick={toogleMenu}>
                  <Image
                     src="/images/header-btn.png"
                     alt="Ícone Menu"
                     width="32"
                     height="32"
                  />
               </button>
            </div>
            <ul className={`${styles.menuList} ${isOpen ? styles.open : styles.close}`}>
               <li>
                  <Link href={`/quem-somos/${params}`}>
                     <a>
                        <div
                           className={`${styles.menuItem} ${pathname.startsWith("/quem-somos") ? "active" : ""
                              }`}
                        >
                           Quem somos
                        </div>
                     </a>
                  </Link>
               </li>
               <li>
                  <Link href={`/curriculo/${params}`}>
                     <a>
                        <div
                           className={`${styles.menuItem} ${pathname.startsWith("/curriculo") ? "active" : ""
                              }`}
                        >
                           Formação
                        </div>
                     </a>
                  </Link>
               </li>
               <li>
                  <Link href={`/pagamento/${params}`}>
                     <a>
                        <div
                           className={`${styles.menuItem} ${pathname.startsWith("/pagamento") ? "active" : ""
                              }`}
                        >
                           Pagamento
                        </div>
                     </a>
                  </Link>
               </li>
               <li>
                  <Link href={`/parceiras/${params}`}>
                     <a>
                        <div
                           className={`${styles.menuItem} ${pathname.startsWith("/parceiras") ? "active" : ""
                              }`}
                        >
                           Para empresas
                        </div>
                     </a>
                  </Link>
               </li>
               <li>
                  <Link href={`/faq/${params}`}>
                     <a>
                        <div
                           className={`${styles.menuItem} ${pathname.startsWith("/faq") ? "active" : ""
                              }`}
                        >
                           Dúvidas
                        </div>
                     </a>
                  </Link>
               </li>
            </ul>
            {pathname.startsWith("/parceiras") ? (
               <button className={styles.cta} onClick={onClick}>
                  Quero ser parceira
                  <svg
                     width="56"
                     height="16"
                     viewBox="0 0 56 16"
                     fill="none"
                     xmlns="http://www.w3.org/2000/svg"
                  >
                     <path
                        d="M55.2765 8.42196C55.667 8.03143 55.667 7.39827 55.2765 7.00774L48.9125 0.643781C48.522 0.253257 47.8888 0.253257 47.4983 0.643781C47.1078 1.03431 47.1078 1.66747 47.4983 2.05799L53.1551 7.71485L47.4983 13.3717C47.1078 13.7622 47.1078 14.3954 47.4983 14.7859C47.8888 15.1764 48.522 15.1764 48.9125 14.7859L55.2765 8.42196ZM0.388794 8.71484L54.5694 8.71485L54.5694 6.71485L0.388794 6.71484L0.388794 8.71484Z"
                        fill="white"
                     />
                  </svg>
               </button>
            ) : pathname.length === 0 || pathname === "/" ? (
               <></>
            ) : (
               <a
                  className={styles.cta}
                  href={`https://inscricao.kenzie.com.br/${params}`}
                  target="_self"
               >
                  Entrar na Lista de Espera
                  <svg
                     width="56"
                     height="16"
                     viewBox="0 0 56 16"
                     fill="none"
                     xmlns="http://www.w3.org/2000/svg"
                  >
                     <path
                        d="M55.2765 8.42196C55.667 8.03143 55.667 7.39827 55.2765 7.00774L48.9125 0.643781C48.522 0.253257 47.8888 0.253257 47.4983 0.643781C47.1078 1.03431 47.1078 1.66747 47.4983 2.05799L53.1551 7.71485L47.4983 13.3717C47.1078 13.7622 47.1078 14.3954 47.4983 14.7859C47.8888 15.1764 48.522 15.1764 48.9125 14.7859L55.2765 8.42196ZM0.388794 8.71484L54.5694 8.71485L54.5694 6.71485L0.388794 6.71484L0.388794 8.71484Z"
                        fill="white"
                     />
                  </svg>
               </a>
            )}
         </nav>
      </header>
   );
}
