export const testimonialsC1 = [
  {
    name: "Annelise Rocha",
    genre: "feminino",
    role: "Aluna da Kenzie",
    testimonial: [
      "“Sou da turma 13 e posso dizer, com certeza, que o conteúdo é muito completo, é uma formação de excelência! Em 1 mês se aprende mais coisa do que em um semestre em cursos tradicionais, é realmente enriquecedor!”",
    ],
    image: "/images/testimonials/student-annelise.webp",
  },
  {
    name: "Naldo Aragon",
    genre: "masculino",
    role: "Aluno da Kenzie",
    testimonial: [
      "“A Kenzie oferece uma didática de ensino diferente do que estamos acostumados. Aqui temos muitos exercícios práticos. A forma como o conteúdo é passado com material escrito, vídeos, projetos práticos é um diferencial.”",
    ],
    image: "/images/testimonials/student-naldo.webp",
  },
];

export const testimonialsC2 = [
  {
    name: "Igor Ribeiro",
    genre: "masculino",
    role: "Aluno da Kenzie",
    testimonial: [
      "“Eu entrei na Kenzie sem saber nada de programação, aprendi do zero. Eu vi na Kenzie uma oportunidade de ter uma carreira promissora investindo apenas 1 ano de estudos para iniciar. Sempre gostei muito do suporte oferecido aos alunos e hoje já trabalhando na área eu posso dizer que sou outra pessoa, o que aprendi na Kenzie me abriu portas para muitas outras coisas.”",
    ],

    image: "/images/testimonials/student-igor.webp",
  },
  {
    name: "Jacqueline Yumi Kajiya",
    genre: "feminino",
    role: "Aluna da Kenzie",
    video: "QtpKLf7zCXI",
    videoHeight: 35,
  },
];

export const testimonialsC3 = [
  {
    name: "Etnan Souza",
    genre: "masculino",
    role: "Aluno da Kenzie",
    video: "6oLD1gJSQlQ",
    videoHeight: 35,
  },
  {
    name: "Joyce Pereira",
    genre: "feminino",
    role: "Aluna da Kenzie",
    testimonial: [
      "“Entrei na Kenzie pois vi que aqui era onde realmente se aprendia programação, antes de decidir tinha optado por uma faculdade porém o tempo era muito longo, e hoje estou trabalhando em uma empresa maravilhosa onde realmente me sinto feliz, a Kenzie tem uma didática incrível de aprendizado que me fez crescer rapidamente.    ",
    ],
    image: "/images/testimonials/student-joyce.webp",
  },
];

export const testimonialsC4 = [
  {
    name: "Susimara Luíza",
    genre: "feminino",
    role: "Aluna da Kenzie",
    testimonial: [
      "“O modelo de ensino focado em prática é uma das coisas que mais deram certo para o meu aprendizado, e junto disso a rotina de estudos tendo atividades todos os dias de semana aliada a disciplina que a Kenzie ensina ao aluno a construir.”",
    ],
    image: "/images/testimonials/student-susimara.webp",
  },
  {
    name: "André Luis Passoni",
    genre: "masculino",
    role: "Aluno da Kenzie",
    testimonial: [
      "“Quando adentrei o mercado de trabalho, que foi graças a conexões que realizei dentro do ecossistema da Kenzie, também percebi que o conhecimento técnico que nos foi passado é realmente de excelente qualidade.”",
    ],
    image: "/images/testimonials/student-andre.webp",
  },
];

export const testimonials = {
  column1: [
    {
      name: "Yuri Caetano",
      genre: "masculino",
      role: "Desenvolvedor Full Stack",
      company: "Contratado pela Mobi7",
      testimonial: [
        "“Estudar na Kenzie fez eu me destacar no mercado de trabalho, na hora da entrevista foi essencial as centenas de horas que estudei para conseguir um emprego, além de conseguir a começar a entender esse vasto mundo da programação.”",
      ],
      image: "/images/resultados-1.png",
    },
    {
      name: "Estevão Kalebe Alves",
      genre: "masculino",
      role: "Desenvolvedor Backend",
      company: "Contratado pela Banco Bari",
      video: "C17m7hItW9Y",
      videoHeight: 65,
    },
    {
      name: "Isabella Pegoraro",
      genre: "feminino",
      role: "Desenvolvedora Full Stack",
      company: "Contratada pela MadeiraMadeira",
      testimonial: [
        "“Consegui minha colocação no mercado de trabalho com 8 meses de estudos na Kenzie. Conheci a Kenzie através da Priscila, que é responsável pelo sucesso do aluno na Kenize, que também é minha irmã, que me fez escolher a Kenzie era o ambiente totalmente diferente do que eu já tinha visto, e como eu não me adaptei muito com o jeito tradicional de uma universidade ensinar, achei o modelo deles bem interessante. Parece que eu sou outra pessoa. Aprendi a aprender e tenho um futuro novo agora com todas as coisas que conquistei durante esse período. Espero ser programadora pra sempre agora haha. Recomendaria sim. É uma experiência que muda sua vida.”",
      ],
      image: "/images/resultados-2.png",
    },
    {
      name: "Bruno Alexandre",
      genre: "masculino",
      role: "Desenvolvedor Full Stack",
      company: "Contratado pela Fast2 Mine",
      video: "9UIYbPe94vs",
      videoHeight: 80,
    },
  ],
  column2: [
    {
      name: "Samara Collet",
      genre: "feminino",
      role: "Desenvolvedora Full Stack",
      company: "Contratada pela PicPay",
      testimonial: [
        "“Sou formada em Contabilidade e troquei de carreira, consegui minha primeira oportunidade no mercado de trabalho, na área de desenvolvimento web front end, com 8 meses de curso na Kenzie. Conheci a Kenzie por recomendação de um amigo e resolvi fazer o curso mais pelo modelo de pagamento que achei muito interessante e pela proposta de suporte e apoio nos estudos, foi a melhor escolha que já fiz, la aprendi a aprender, e sem a Kenzie e o suporte dos profissionais de la eu duvido que teria me desenvolvido tanto em tão pouco tempo, e também duvido que estaria preparada para o mercado de desenvolvimento em tão pouco tempo.”",
      ],
      image: "/images/resultados-3.png",
    },
    {
      name: "Fracielle Da Silva",
      genre: "feminino",
      role: "Desenvolvedora Frontend",
      company: "Contratada pela Beep Saúde",
      video: "1Yx4mVDNWvw",
      videoHeight: 60,
    },
    {
      name: "Ingrid Benites",
      genre: "feminino",
      role: "Desenvolvedora Full Stack",
      company: "Contratada pela First Ascent",
      testimonial: [
        "“Aprendi programação do zero com a Kenzie e é incrível como em apenas 6 meses eu já consegui um emprego na área como desenvolvedora web e trabalhando com algo que eu sempre sonhei. E melhor ainda, contratada por uma empresa dos Estados Unidos. Aqui as opotunidades são reais!”",
      ],
      image: "/images/resultados-4.png",
    },
    {
      name: "Mariana Antunes",
      genre: "feminino",
      role: "Desenvolvedora Full Stack",
      company: "Contratada pela Kenzie Academy",
      testimonial: [
        "“Eu já queria mudar de carreira, justamente para colocar minha vida financeira em ordem. Quando a Kenzie apareceu com o modelo ISA de pagamento, foi a minha solução. Agora eu faço um curso de qualidade, e so pago ele, quando entrar no mercado, ganhando um bom salário.”",
      ],
      image: "/images/resultados-5.png",
    },
  ],
  column3: [
    {
      name: "Augusto Pietroski",
      genre: "masculino",
      role: "Desenvolvedor Full Stack",
      company: "Contratado pela Code Fiction",
      video: "T8bUpVc33hQ",
      videoHeight: 25,
    },
    {
      name: "Viktor Fagionato",
      genre: "masculino",
      role: "Desenvolvedor Full Stack",
      company: "Contratado pela Huge Networks",
      testimonial: [
        "“Consegui minha recolocação no mercado no final do Q2, uns 5 meses de curso. Eu sou formado em Publicidade e Propaganda, mas depois de ter uma experiência na área e ver que não era isso que gostaria de fazer pro resto da minha vida resolvi resgatar uma antiga paixão que tinha pela programação. Fui impactado pelo ADS no YouTube, pois usava muito para estudar por conta. Foi uma quebra muito grande de paradigmas, pelo menos pra mim, a kenzie te coloca como protagonista e fica de apoio para seus estudos, a Kenzie me deu confiança para encarar qualquer problema no mundo da programação. Para qualquer amigo que esteja pensando em mudar ou começar uma carreira na programação, a Kenzie sempre vai ser a minha recomendação! O que mais fez diferença para mim foi o suporte ao aluno, são pessoas que estão realmente preocupadas que você aprenda o conteúdo e não que decore apenas para passar nos testes ou fazer os exercícios.”",
      ],
      image: "/images/resultados-6.png",
    },
    {
      name: "Júlia Sperandio",
      genre: "feminino",
      role: "Desenvolvedora Full Stack",
      company: "Contratada pela minu.co",
      video: "lBpHLDgpWJA",
      videoHeight: 50,
    },
  ],
};
