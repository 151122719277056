import { StudentTestimonial } from "../../shared/models/student";
import LiteYouTubeEmbed from "react-lite-youtube-embed";
import Image from "next/image";
import styles from "./styles.module.scss";
import { useEffect, useState } from "react";

interface TestimonialCardProps {
  student: StudentTestimonial;
  height?: number;
}

export default function TestimonialCard({
  student,
  height,
}: TestimonialCardProps) {
  const [isDesktop, setIsDesktop] = useState(false);

  function handleResize() {
    if (window.innerWidth >= 1024) {
      setIsDesktop(true);
    } else {
      setIsDesktop(false);
    }
  }

  useEffect(() => {
    if (typeof window !== "undefined") {
      window.addEventListener("resize", handleResize);
      handleResize();
    }

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  function removeMask(name: string) {
    const video = document.querySelector(`.${name}`);
    video?.classList.add(styles.hidden);
  }

  return (
    <>
      {student.video ? (
        <article
          className={`${styles.testimonial} ${styles.video}`}
          onClick={() =>
            removeMask(student.name.split(" ")[0].toLocaleLowerCase())
          }
          style={{
            height: isDesktop && height ? `${height}rem` : "100%",
          }}
        >
          <LiteYouTubeEmbed
            id={student.video}
            title={`Video de depoimento - ${student.name}`}
            poster="mqdefault"
            params="rel=0"
          />
          <div
            className={`${styles.titleContent} ${styles.video} ${student.name
              .split(" ")[0]
              .toLocaleLowerCase()}`}
          >
            <div className={styles.playIcon} />
            <div>
              <h3 className={styles.video}>{student.name}</h3>
              <span
                className={styles.video}
                style={{ fontSize: student.company && "0.75rem" }}
              >
                {student.role}
              </span>
              {student.company && (
                <span className={styles.video} style={{ fontSize: "0.75rem" }}>
                  {student.company}
                </span>
              )}
            </div>
          </div>
        </article>
      ) : (
        <article className={styles.testimonial}>
          <div className={styles.testimonialHeader}>
            <Image
              src={student.image || ""}
              width="43"
              height="43"
              alt={`Foto ${
                student.genre === "masculino" ? "do aluno" : "da aluna"
              } ${student.name}`}
            />
            <div className={styles.titleContent}>
              <h3>{student.name}</h3>
              <span style={{ fontSize: student.company && "0.75rem" }}>
                {" "}
                {`${student.role}`}
              </span>
              {student.company && (
                <span style={{ fontSize: "0.75rem" }}>{student.company}</span>
              )}
            </div>
          </div>
          {student.testimonial &&
            student.testimonial.map((p, index) => (
              <p className={styles.testimonialContent} key={`${p}${index}`}>
                {p}
              </p>
            ))}
        </article>
      )}
    </>
  );
}
