import Link from "next/link";
import { useEffect, useState } from "react";
import { useParams } from "../../hooks/Params";
import styles from "./styles.module.scss";

interface CtaProps {
  maxWidthDesktop: string;
  maxWidthMobile?: string;
  color: "white" | "blue";
  text: string;
  url: string;
  passParams: boolean;
  isLocalUrl?: boolean;
  centralized?: boolean;
  background?: boolean;
  target?: string;
  margin?: string;
}

export default function Cta({
  maxWidthDesktop,
  maxWidthMobile,
  color,
  text,
  url,
  passParams,
  isLocalUrl = false,
  centralized = true,
  background,
  target,
  margin,
}: CtaProps) {
  const [isDesktop, setIsDesktop] = useState(false);

  function handleResize() {
    if (window.innerWidth >= 1024) {
      setIsDesktop(true);
    } else {
      setIsDesktop(false);
    }
  }

  useEffect(() => {
    if (typeof window !== "undefined") {
      window.addEventListener("resize", handleResize);
      handleResize();
    }

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const { params } = useParams();
  if (passParams) {
    url += params;
  }

  return (
    <>
      {!isLocalUrl ? (
        <a
          className={`${styles.container} ${color === "blue" && styles.blue} ${
            background && styles.background
          }`}
          target={target ?? "_self"}
          href={url}
          style={{
            margin: centralized ? "2.5rem auto 0" : margin,
            maxWidth:
              !isDesktop && maxWidthMobile ? maxWidthMobile : maxWidthDesktop,
          }}
        >
          {text}
          <svg width="56" height="16" viewBox="0 0 56 16" fill="none">
            <path
              d="M55.2765 8.42196C55.667 8.03143 55.667 7.39827 55.2765 7.00774L48.9125 0.643781C48.522 0.253257 47.8888 0.253257 47.4983 0.643781C47.1078 1.03431 47.1078 1.66747 47.4983 2.05799L53.1551 7.71485L47.4983 13.3717C47.1078 13.7622 47.1078 14.3954 47.4983 14.7859C47.8888 15.1764 48.522 15.1764 48.9125 14.7859L55.2765 8.42196ZM0.388794 8.71484L54.5694 8.71485L54.5694 6.71485L0.388794 6.71484L0.388794 8.71484Z"
              fill={`${color === "white" ? "#fff" : "#0000ff"}`}
            />
          </svg>
        </a>
      ) : (
        <Link href={url} passHref>
          <a
            className={`${styles.container} ${
              color === "blue" && styles.blue
            } ${background && styles.background}`}
            style={{
              margin: centralized ? "2.5rem auto 0" : margin,
              maxWidth:
                !isDesktop && maxWidthMobile ? maxWidthMobile : maxWidthDesktop,
            }}
          >
            {text}
            <svg width="56" height="16" viewBox="0 0 56 16" fill="none">
              <path
                d="M55.2765 8.42196C55.667 8.03143 55.667 7.39827 55.2765 7.00774L48.9125 0.643781C48.522 0.253257 47.8888 0.253257 47.4983 0.643781C47.1078 1.03431 47.1078 1.66747 47.4983 2.05799L53.1551 7.71485L47.4983 13.3717C47.1078 13.7622 47.1078 14.3954 47.4983 14.7859C47.8888 15.1764 48.522 15.1764 48.9125 14.7859L55.2765 8.42196ZM0.388794 8.71484L54.5694 8.71485L54.5694 6.71485L0.388794 6.71484L0.388794 8.71484Z"
                fill={`${color === "white" ? "#fff" : "#0000ff"}`}
              />
            </svg>
          </a>
        </Link>
      )}
    </>
  );
}
