import TestimonialCard from "../../TestimonialCard";
import Title from "../../Title";
import {
  testimonialsC1,
  testimonialsC2,
  testimonialsC3,
  testimonialsC4,
} from "../../../shared/utils/testimonials";
import Cta from "../../Cta";
import styles from "./styles.module.scss";
import { useMediaQuery } from "react-responsive";

interface TestimonialsProps {
  mixedBackground?: boolean;
}

export default function Testimonials({
  mixedBackground = false,
}: TestimonialsProps) {
  const maxWidthMobile = useMediaQuery({
    query: "(min-width: 488px)",
  });

  return (
    <section
      className={`${
        mixedBackground ? styles.mixedBackground : styles.background
      } ${!mixedBackground && styles.paddingBottom}`}
    >
      <div className={styles.content}>
        <div className={styles.testimonialsContent}>
          <Title color="#ffffff">A Kenzie vale a pena?</Title>
          <div className={styles.testimonials} data-aos="fade-down">
            <div className={styles.testimonialColumn}>
              {testimonialsC1.map((t) => (
                <TestimonialCard student={t} height={35} key={t.name} />
              ))}
            </div>
            <div
              className={`${styles.testimonialColumn} ${styles.columnDisplay}`}
            >
              {testimonialsC2.map((t) => (
                <TestimonialCard
                  student={t}
                  height={t.videoHeight || 35}
                  key={t.name}
                />
              ))}
            </div>
            <div
              className={`${styles.testimonialColumn} ${styles.columnDisplay}`}
            >
              {testimonialsC3.map((t) => (
                <TestimonialCard
                  student={t}
                  height={t.videoHeight || 35}
                  key={t.name}
                />
              ))}
            </div>
            <div
              className={`${styles.testimonialColumn} ${styles.columnDisplay}`}
            >
              {testimonialsC4.map((t) => (
                <TestimonialCard student={t} height={35} key={t.name} />
              ))}
            </div>
          </div>
        </div>
      </div>
      <Cta
        url="/resultados"
        maxWidthDesktop="44.5rem"
        maxWidthMobile={maxWidthMobile ? "28rem" : "19rem"}
        color={mixedBackground ? "white" : "blue"}
        passParams
        text="Veja como a Kenzie transforma a vida dos alunos"
        isLocalUrl
        margin={maxWidthMobile ? "2.5rem auto" : "0 "}
      />
    </section>
  );
}
