import styles from "./styles.module.scss";
import { Card } from "../../shared/models/card";
import { useEffect, useState } from "react";

interface GenericCardProps {
  content: Card;
  background: string;
  textColor: string;
  height?: string;
}

export default function GenericCard({
  content,
  background,
  textColor,
  height,
}: GenericCardProps) {
  const [isDesktop, setIsDesktop] = useState(false);

  function handleResize() {
    if (window.innerWidth >= 1024) {
      setIsDesktop(true);
    } else {
      setIsDesktop(false);
    }
  }

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    handleResize();
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <div
      className={styles.container}
      style={{
        background: background,
        color: textColor,
        height: isDesktop ? (height ? height : "350px") : "auto",
      }}
      data-aos="fade-down"
    >
      <div>
        <img src={content.image} alt={content.title} height="48" width="48" />
        <h3>{content.title}</h3>
      </div>

      <p>{content.paragraph}</p>

      {!!content.note && <span>{content.note}</span>}
    </div>
  );
}
